
























































import { Component, Vue, PropSync } from "vue-property-decorator";
import etiquetaModule from "@/store/modules/etiqueta-module";
import MarketingModule from "@/store/modules/Marketing-module";
import { etiqueta } from "@/shared/dtos/etiqueta";
import { Marketing } from "@/shared/dtos/Marketing";
import { UtilsNotify } from "@/utils/utils-notify";
@Component({
  components: {}
})
export default class envio_camapana_marketing extends Vue {
  @PropSync("dialog", {}) dialogsync!: boolean;
  public etiquetas_seleccionadas: any[] = [];
  public campana: Marketing = new Marketing();

  public created() {
    etiquetaModule.getetiquetas_from_tipo(2);
  }
  public get Etiquetas() {
    return etiquetaModule.etiquetas_for_vuetify;
  }

  public sendMail() {
    this.campana.etiquetas = [];
    this.etiquetas_seleccionadas.forEach(element => {
      let eti: etiqueta = new etiqueta();
      eti.id_tipoetiqueta = element;
      eti.id = element;
      this.campana.etiquetas.push(eti);
    });
    MarketingModule.sendMarketing(this.campana).then(() => {
      UtilsNotify.NotificacionSuccess(
        "Campaña de marketing",
        "se ha enviado correctamente la campaña a los pacientes"
      );
      this.dialogsync = false;
    });
  }
}
