import { store } from '@/store/store';
import { Marketing } from '@/shared/dtos/Marketing';
import {
    VuexModule,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'MarketingModule',
    store,
    dynamic: true
})
class MarketingModule extends VuexModule {

    @Action
    public async sendMarketing(Marketing: Marketing) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.Marketing, Marketing.toJson());
    }

}
export default getModule(MarketingModule);